import React from "react";

function SvgPencil(props) {
  return (
    <svg height={512} viewBox="0 0 512.081 512.081" width={512} {...props}>
      <path
        d="M21.958 490.097c29.31 29.31 76.752 29.313 106.065 0l127.995-127.995 149.97 149.97 106.066-106.066-149.97-149.97 96.841-96.841L511.959.096 352.86 53.129l-96.764 96.763L106.204 0 .138 106.066l149.893 149.893L21.958 384.031c-29.243 29.243-29.243 76.824 0 106.066zm360.218-381.431l-137.48 137.479 21.213 21.213 137.479-137.479 21.213 21.213-201.118 201.119-63.639-63.64L360.963 87.453zM202.27 373.424l-63.64 63.64-63.64-63.64 63.64-63.64zm161.293-73.484l-42.426 42.427 21.213 21.213 42.426-42.427 21.213 21.213-42.426 42.426 21.213 21.213 42.426-42.426 42.426 42.426-63.639 63.64-128.757-128.756 63.64-63.64zm75.697-176.616l-50.529-50.529 75.794-25.265zM42.565 106.066l63.639-63.64 42.427 42.426-42.426 42.426 21.213 21.213 42.426-42.426 21.214 21.214-42.426 42.426 21.213 21.213 42.427-42.427 22.613 22.613-63.64 63.64zm.606 299.178l10.607-10.607 63.64 63.64-10.607 10.607c-17.545 17.546-46.094 17.545-63.64 0-17.545-17.546-17.545-46.094 0-63.64z"
        fill="#008BB9"
      />
      <path
        d="M159.895 373.452l-21.21 21.21-21.21-21.21 21.21-21.21z"
        fill="#008BB9"
      />
    </svg>
  );
}

export default SvgPencil;
