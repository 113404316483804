import React from "react";

function SvgRocket(props) {
  return (
    <svg height={512} viewBox="0 0 512.049 512.049" width={512} {...props}>
      <path
        d="M467.024 422.049h-17.49c-6.775-33.66-36.67-61-73.51-61a74.653 74.653 0 00-53.986 23.034c-12.261-7.847-20.212-21.174-20.942-35.831l15.631-47.203h74.297v-43.732c0-37.288-26.905-68.39-62.316-74.967a381.118 381.118 0 00-3.608-30.683C308.455 46.995 260.611 7.276 256.024 0c-4.552 7.222-52.426 46.969-69.076 151.667a381.625 381.625 0 00-3.608 30.683c-35.411 6.576-62.316 37.678-62.316 74.966v43.732h74.297l15.631 47.202c-.73 14.658-8.681 27.984-20.942 35.831a74.657 74.657 0 00-53.986-23.034c-36.845 0-66.736 27.346-73.51 61h-17.49c-24.813 0-45 20.187-45 45s20.187 45 45 45h422c24.813 0 45-20.187 45-45s-20.187-44.998-45-44.998zm-248.837-151c-10.11-46.056-7.216-101.344 6.032-150h63.608c13.243 48.645 16.137 103.926 6.029 150zm142.837-13.732v13.732h-36.613c3.432-18 5.242-37.418 5.387-57.476 18.148 6.258 31.226 23.497 31.226 43.744zm-105-209.395c8.453 12.684 15.814 27.289 21.969 43.127h-43.939c6.156-15.838 13.517-30.443 21.97-43.127zm-105 223.127v-13.732c0-20.246 13.077-37.485 31.225-43.744.144 20.063 1.954 39.483 5.385 57.476zm134.106 30l-9.938 30h-38.335l-9.938-30zm181.894 181h-422c-8.271 0-15-6.729-15-15s6.729-15 15-15h46v-15c0-24.935 20.607-46 45-46 30.191 0 40.52 26.366 43.826 29.782l11.042-3.964c25.143-9.026 43.369-30.494 48.6-55.818h33.065c5.231 25.324 23.457 46.792 48.6 55.818l11.042 3.964c3.207-3.315 13.72-29.782 43.826-29.782 24.393 0 45 21.065 45 46v15h46c8.271 0 15 6.729 15 15s-6.73 15-15.001 15z"
        fill="#008BB9"
      />
      <path
        d="M241.024 211.049h30v30h-30zM241.024 151.049h30v30h-30z"
        fill="#008BB9"
      />
    </svg>
  );
}

export default SvgRocket;
