import React from "react";

function SvgCode(props) {
  return (
    <svg height={512} viewBox="0 0 512.049 512.049" width={512} {...props}>
      <g fill="#008BB9">
        <path d="M512 421.928V30.024H0v391.904h180.952v30.048h-60.06v30h270.216v-30h-60.06v-30.048zM482 60.024V331.88H30V60.024zM30 361.88h452v30.048H30zm271.048 90.096h-90.096v-30.048h90.096z" />
        <path d="M210.342 245.955l-50.003-50.003 50.003-50.003-21.213-21.213-71.216 71.216 71.216 71.216zM322.871 267.168l71.216-71.216-71.216-71.216-21.213 21.213 50.003 50.003-50.003 50.003zM241 120.331h30v151.243h-30z" />
      </g>
    </svg>
  );
}

export default SvgCode;
